<template>
  <section>
    <b-overlay :show="show" rounded="sm">
      <b-row class="pricing-card">
        <b-col class="mx-auto">
          <b-row>
            <b-col cols="12">
              <b-card>
                <h1>วิธีชำระเงิน</h1>
                <p>ดูรายละเอียดวิธีชำระเงินและเปลี่ยนวิธีชำระเงินได้ที่นี่</p>
                <hr />

                <div align="center">
                  ผู้ชำระเงิน :
                  <span v-if="count_card_credit !== 0">
                  {{ card_credit.name  }}
                 </span>
                 <span v-else>ยังไม่ลงทะเบียน</span>  
                </div>
                <br />
                <div align="center">
                  วิธีชำระเงิน :
                  <span v-if="count_card_credit !== 0">
                    {{ card_credit.object }} {{ card_credit.brand }} **** ******** {{ card_credit.last_digits }}</span
                  >
                  <span v-else>ยังไม่ลงทะเบียน</span>  <br><br>
                  <b-button v-if="count_card_credit !== 0" variant="primary" @click="update_card_credit = !update_card_credit">
                    เปลี่ยนวิธีการชำระเงิน
                  </b-button>
                </div>
                <hr />

               <div style="margin:auto; width:480px">
                <b-card no-body class="card-payment" v-if="count_card_credit === 0 || update_card_credit == true">
                  <b-row>
                    <b-col >

                      <div class="blue box ex2">
                        <div class="coral item">
                          <span class="pink item">
                           <h2>
                            <b style="margin-right: 10px">Credit Card</b>
                           </h2>
                          </span>
                        </div>
                        <div class="coral item">
                    
                          <span class="pink item"> 
                            <img
                            width="40px"
                            :src="require('@/assets/img_payment_card_visa.png')"
                            alt=""
                            style="margin-right: 2px"
                          />
                          <img
                            width="40px"
                            :src="require('@/assets/img_payment_card_master.png')"
                            alt=""
                            style="margin-right: 5px"
                          />
                          <img
                            width="30px"
                            :src="require('@/assets/img_payment_card_jcb.png')"
                            alt=""
                            style="margin-right: 5px"
                          />
                          <img
                            width="50px"
                            :src="require('@/assets/img_payment_tpn.png')"
                            alt=""
                          />
                          </span>
                        </div>
                      </div>


                    </b-col>
                  </b-row>

                  <b-card >
                    <b-form>
                      <b-row>
                        <b-col cols="12">
                          <b-form-group
                            label="หมายเลขบัตร"
                            label-for="payment-card-number"
                            class="mb-2"
                          >
                            <cleave
                              id="payment-card-number"
                              v-model="form.cardNumber"
                              class="form-control"
                              :raw="false"
                              :options="options.creditCard"
                              placeholder="XXXX XXXX XXXX XXXX"
                            />
                          </b-form-group>
                        </b-col>

                        <b-col sm="6">
                          <b-form-group
                          label="เดือน/ปี ที่หมดอายุ"
                            label-for="expiry"
                            class="mb-2"
                          >
                            <cleave
                              v-model="form.expiry"
                              class="form-control"
                              :raw="false"
                              :options="options.expiry"
                              placeholder="XX/XX"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col sm="6">
                          <b-form-group
                          label="รหัสหลังบัตร"
                            label-for="cvv"
                            class="mb-2"
                          >
                            <input
                              class="form-control"
                              v-model="form.cvv"
                              type="password"
                              placeholder="XXX"
                              maxlength="3"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols="12">
                          <b-form-group
                            label="ชื่อเจ้าของบัตร"
                            label-for="name"
                            class="mb-2"
                          >
                            <b-form-input id="name" v-model="form.cardName" />
                          </b-form-group>
                        </b-col>
                        <br />
                        <b-col cols="12">
                          <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            block
                            variant="primary"
                            @click="save_card()"
                          >
                            บันทึก
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-form>
                  </b-card>
                </b-card>
               </div>
              </b-card>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <template #overlay>
        <div class="text-center">
          <div class="loading">
            <div class="effect-1 effects"></div>
            <div class="effect-2 effects"></div>
            <div class="effect-3 effects"></div>
          </div>
          <div style="margin-top: 15em">
            <h4 id="cancel-label">กรุณารอสักครู่</h4>
            <h4 id="cancel-label">กำลังทำรายการ...</h4>
          </div>
        </div>
      </template>
    </b-overlay>
  </section>
</template>

<script>
import {
  BFormCheckbox,
  BRow,
  BCol,
  BCard,
  BImg,
  BCardText,
  BListGroup,
  BListGroupItem,
  BButton,
  BBadge,
  BModal,
  VBModal,
  BFormRadioGroup,
  BFormGroup,
  BFormInput,
  BForm,
  BFormText,
  BFormDatalist,
  BFormSelect,
  BCardBody,
  BInputGroup,
  BInputGroupPrepend,
  BFormTextarea,
  BPopover,
  BAlert,
  BLink,
  BOverlay,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Ripple from "vue-ripple-directive";
import { required, email } from "@validations";
import Logo from "@core/layouts/components/Logo.vue";
import Cleave from "vue-cleave-component";
import api from "@/api";
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
    BButton,
    BCardText,
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
    BCard,
    BBadge,
    BImg,
    BModal,
    BFormRadioGroup,
    BFormGroup,
    BFormInput,
    BForm,
    BFormText,
    BFormDatalist,
    BFormSelect,
    BCardBody,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BPopover,
    BAlert,
    BLink,
    Logo,
    Cleave,
    BOverlay,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      show: false,
      update_card_credit: false,
      cardNumber: "",
      Remember_me: true,
      options: {
        creditCard: {
          creditCard: true,
        },
        number: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
        },
        expiry: {
          delimiter: "/",
          blocks: [2, 2],
          uppercase: true,
        },
      },
      // form: {
      //   cardNumber: null,
      //   expiry: null,
      //   cvv: null,
      //   cardName: null,
      //   id_update: null,
      // },
      form: {
        cardNumber: 4242424242424242,
        expiry: "0330",
        cvv: 123,
        cardName: "JOHN DOE",
        id_update: null,
      },
      card_credit: null,
      omise_card_credit: null,
      count_card_credit: null,
      // form: {
      //   cardNumber: null,
      //   expiry: null,
      //   cvv: null,
      //   cardName: null,
      // },
    };
  },
  mounted() {
    this.loaddata();
  },
  methods: {
    async loaddata() {
      this.show = true;
      api
        .get("user_card_by_omise", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log("response.user_card_by_omise :>> ", response.data);
          this.update_card_credit = false
          // console.log('response.data.result :>> ', response.data.result);
          this.card_credit = response.data.result ? response.data.result : null
          this.count_card_credit = response.data.count_card_credit;
          this.omise_card_credit = response.data.omise_card_credit;
         this.form.id_update  = this.omise_card_credit  ? this.omise_card_credit.id :null
          this.show = false;
        })
        .catch((error) => {
          console.log("error :>> ", error);
        });
    },
    save_card() {
      console.log("this,form :>> ", this.form);
      if (
        this.form.cardNumber &&
        this.form.expiry &&
        this.form.cvv &&
        this.form.cardName
      ) {
        this.show = true;
        api
          .post("omise_addCustomers", this.form, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("accessToken"),
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            this.loaddata();
            this.show = false;

            console.log("response.data :>> ", response.data);
            this.$swal({
              title: "เพิ่ม Credit Card สำเร็จ",
              icon: "success",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          })
          .catch((error) => {
            this.show = false;

            this.$swal({
              title: "Error!",
              text: " โปรดลองใหม่อีกครั้ง!",
              icon: "error",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          });
      } else {
        this.show = false;

        this.$swal({
          title: "Error!",
          text: " ตรวจสอบข้อมูลให้เรียบร้อย!",
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      }
    },
   
  },
};
/* eslint-disable global-require */
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-pricing.scss";
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: 0.35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}
</style>
